import * as React from "react"

import Seo from "../components/Seo"
import WelcomeMessage from "../components/WelcomeMessage"
import GuestModal from "../components/Modal"

const IndexPage = () => (
  <>
    <Seo title="Inicio" />
    {/* <GuestModal /> */}
    <WelcomeMessage />
    <iframe className="iframemap-360" src="https://app.cloudpano.com/tours/pe4BTKNKj" allowfullscreen="" frameborder="0" allow="camera;microphone;vr;accelerometer;gyroscope;fullscreen" title="CENHIES 360"></iframe>
    {/* <div id="pe4BTKNKj">
      <Helmet>
        <script type="text/javascript" async data-short="pe4BTKNKj" width="100%" height="500px" src="https://app.cloudpano.com/public/shareScript.js"></script>
      </Helmet>
    </div> */}
  </>
)

export default IndexPage
